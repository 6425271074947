
import { Component, PropSync, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class SocialButtons extends Vue {
    @Prop({ required: false, type: String, default: "submit" }) type!: string;
    @Prop({ required: false, type: Boolean, default: false }) block!: boolean;
    @Prop({ required: false, type: Boolean, default: false }) large!: boolean;
    @Prop({ required: false, type: Boolean, default: false }) xLarge!: boolean;
    @Prop({ required: false, type: Boolean, default: false }) tile!: boolean;
    @Prop({ required: false, type: Boolean, default: false }) small!: boolean;
    @Prop({ required: false, type: String, default: "primary" }) color!: string;
    @Prop({ required: false, type: String, default: null }) icon!:
        | string
        | null;
    @PropSync("isLoading", { required: true, type: Boolean }) loading!: boolean;

    isClicked = false;

    @Watch("loading")
    onChildChanged(submitting: boolean) {
        if (!submitting && this.isClicked) {
            this.isClicked = false;
        }
    }
    onClick() {
        this.isClicked = true;
        this.$emit("click");
    }
}
