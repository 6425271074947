
import { Component, Prop, Vue } from "vue-property-decorator";
import { mdiTrashCan } from "@mdi/js";
@Component
export default class DeleteButton extends Vue {
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: false }) small!: boolean;
    @Prop({ default: false }) block!: boolean;
    @Prop({ default: false }) withIcon!: boolean;
    @Prop({ required: false, type: String, default: "item" })
    itemText!: string;
    @Prop({ required: false, default: true, type: Boolean })
    text!: boolean;
    deleteIcon: string = mdiTrashCan;
    dialog = false;
    emitConfirmed() {
        this.dialog = false;
        this.$emit("confirmed");
    }
}
